{
  "Address": {
    "comments": [],
    "tags": {},
    "name": "Address",
    "type": "Address",
    "literal": true,
    "content": [
      {
        "name": "line1",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Line 1"
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "name": "line2",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Line 2"
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "name": "city",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "City"
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "name": "state",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "State / Province"
          },
          "optionsType": {
            "value": "StateOptions"
          },
          "allowCustomValue": {
            "value": true
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "name": "country",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Country"
          },
          "optionsType": {
            "value": "CountryOptions"
          },
          "allowCustomValue": {
            "value": true
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "name": "zip",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Zip"
          }
        },
        "optional": true,
        "readonly": false
      }
    ]
  },
  "CountryOptions": {
    "comments": [],
    "tags": {},
    "name": "CountryOptions",
    "type": "CountryOptions",
    "varietyType": true,
    "content": [
      {
        "name": "CountryOptions",
        "type": "'AF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AX'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'DZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AQ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BB'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BJ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BQ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CX'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'HR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'DK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'DJ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'DM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'DO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'EC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'EG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GQ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ER'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'EE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ET'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'FK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'FO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'FJ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'FI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'FR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'DE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GP'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'HT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'HM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'VA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'HN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'HK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'HU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ID'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IQ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'IT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'JM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'JP'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'JE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'JO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KP'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LB'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ML'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MQ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'YT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MX'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'FM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ME'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NP'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MP'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'NO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'OM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'QA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'RE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'RO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'RU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'RW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'BL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'KN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'MF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'PM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'VC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'WS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ST'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'RS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SX'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SB'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ZA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ES'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'LK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SJ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'CH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'SY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TW'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TJ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'TV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'UG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'UA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'AE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'GB'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'US'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'UM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'UY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'UZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'VU'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'VE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'VN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'VG'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'VI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'WF'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'EH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'YE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ZM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "CountryOptions",
        "type": "'ZW'",
        "literal": true,
        "comments": [],
        "tags": {}
      }
    ]
  },
  "Education": {
    "comments": [],
    "tags": {},
    "name": "Education",
    "type": "Education",
    "literal": true,
    "content": [
      {
        "name": "establishment",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Establishment"
          }
        },
        "optional": false,
        "readonly": false
      },
      {
        "name": "program",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Program"
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Start Date"
          }
        },
        "name": "startDate",
        "type": "Education.startDate",
        "varietyType": true,
        "content": [
          {
            "comments": [],
            "tags": {},
            "name": "startDate",
            "type": "Date"
          },
          {
            "name": "startDate",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {}
          }
        ],
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "End Date"
          }
        },
        "name": "endDate",
        "type": "Education.endDate",
        "varietyType": true,
        "content": [
          {
            "comments": [],
            "tags": {},
            "name": "endDate",
            "type": "Date"
          },
          {
            "name": "endDate",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {}
          }
        ],
        "optional": false,
        "readonly": false
      },
      {
        "name": "credentialType",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Credential Type"
          },
          "options": {
            "value": " Individual Courses\n Certificate\n Associate\n Bachelors\n Masters\n Doctorate\n Professional\n PHD"
          },
          "allowCustomValue": {
            "value": true
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Achievements"
          }
        },
        "name": "achievements",
        "type": "string",
        "literal": true,
        "multiple": 1,
        "optional": true,
        "readonly": false
      }
    ]
  },
  "Employment": {
    "comments": [],
    "tags": {},
    "name": "Employment",
    "type": "Employment",
    "literal": true,
    "content": [
      {
        "name": "company",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Company"
          }
        },
        "optional": false,
        "readonly": false
      },
      {
        "name": "position",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Position"
          }
        },
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Start Date"
          }
        },
        "name": "startDate",
        "type": "Employment.startDate",
        "varietyType": true,
        "content": [
          {
            "comments": [],
            "tags": {},
            "name": "startDate",
            "type": "Date"
          },
          {
            "name": "startDate",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {}
          }
        ],
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "End Date"
          }
        },
        "name": "endDate",
        "type": "Employment.endDate",
        "varietyType": true,
        "content": [
          {
            "comments": [],
            "tags": {},
            "name": "endDate",
            "type": "Date"
          },
          {
            "name": "endDate",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {}
          }
        ],
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Projects"
          },
          "itemName": {
            "value": "`name`"
          }
        },
        "name": "projects",
        "type": "Project",
        "multiple": 1,
        "optional": true,
        "readonly": false
      }
    ]
  },
  "NotableConcept": {
    "comments": [],
    "tags": {
      "label": {
        "value": "Notable Concept"
      }
    },
    "name": "NotableConcept",
    "type": "NotableConcept",
    "literal": true,
    "content": [
      {
        "name": "description",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Description"
          }
        },
        "optional": false,
        "readonly": false
      }
    ]
  },
  "Person": {
    "comments": [],
    "tags": {
      "layout": {
        "value": "  name\n  description\n  phone email\n  address\n  socialNetworks"
      },
      "label": {
        "value": "Person"
      },
      "itemName": {
        "value": "`name`"
      }
    },
    "name": "Person",
    "type": "Person",
    "literal": true,
    "content": [
      {
        "comments": [],
        "tags": {
          "layout": {
            "value": "  first middle last"
          },
          "itemName": {
            "value": "`first` `last`"
          }
        },
        "name": "name",
        "type": "Person.name",
        "literal": true,
        "content": [
          {
            "name": "first",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {
              "label": {
                "value": "First Name"
              }
            },
            "optional": false,
            "readonly": false
          },
          {
            "name": "middle",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {
              "label": {
                "value": "Middle Name"
              }
            },
            "optional": true,
            "readonly": false
          },
          {
            "name": "last",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {
              "label": {
                "value": "Last Name"
              }
            },
            "optional": false,
            "readonly": false
          }
        ],
        "optional": false,
        "readonly": false
      },
      {
        "name": "description",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Description"
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Phone"
          }
        },
        "name": "phone",
        "type": "TelephoneNumber",
        "optional": true,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Email"
          }
        },
        "name": "email",
        "type": "EmailAddress",
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Social Networks"
          }
        },
        "name": "socialNetworks",
        "type": "SocialNetwork",
        "multiple": 1,
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Address"
          },
          "inline": {
            "value": true
          },
          "layout": {
            "value": "  line1\n  line2\n  city state zip\n  country"
          }
        },
        "name": "address",
        "type": "Address",
        "optional": false,
        "readonly": false
      }
    ]
  },
  "Project": {
    "comments": [],
    "tags": {},
    "name": "Project",
    "type": "Project",
    "literal": true,
    "content": [
      {
        "name": "name",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Name"
          }
        },
        "optional": false,
        "readonly": false
      },
      {
        "name": "description",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Description"
          }
        },
        "optional": true,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Notable Concepts"
          },
          "itemName": {
            "value": "`description`"
          }
        },
        "name": "notableConcepts",
        "type": "NotableConcept",
        "multiple": 1,
        "optional": true,
        "readonly": false
      }
    ]
  },
  "Resume": {
    "comments": [],
    "tags": {
      "label": {
        "value": "Resume"
      }
    },
    "name": "Resume",
    "type": "Resume",
    "literal": true,
    "content": [
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Subject"
          }
        },
        "name": "subject",
        "type": "Person",
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Objective"
          }
        },
        "name": "objective",
        "type": "LongText",
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Date"
          }
        },
        "name": "date",
        "type": "Resume.date",
        "varietyType": true,
        "content": [
          {
            "comments": [],
            "tags": {},
            "name": "date",
            "type": "Date"
          },
          {
            "name": "date",
            "type": "string",
            "literal": true,
            "comments": [],
            "tags": {}
          }
        ],
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Skills"
          },
          "itemName": {
            "value": "`description`"
          }
        },
        "name": "skills",
        "type": "Skill",
        "multiple": 1,
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Employment"
          },
          "itemName": {
            "value": "`company`"
          }
        },
        "name": "employment",
        "type": "Employment",
        "multiple": 1,
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Education"
          },
          "itemName": {
            "value": "`establishment`"
          }
        },
        "name": "education",
        "type": "Education",
        "multiple": 1,
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "References"
          },
          "itemName": {
            "value": "`name`"
          }
        },
        "name": "references",
        "type": "Person",
        "multiple": 1,
        "optional": false,
        "readonly": false
      }
    ]
  },
  "Skill": {
    "comments": [],
    "tags": {},
    "name": "Skill",
    "type": "Skill",
    "literal": true,
    "content": [
      {
        "name": "description",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Description"
          }
        },
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "Rating"
          }
        },
        "name": "rating",
        "type": "Rating",
        "optional": false,
        "readonly": false
      }
    ]
  },
  "SocialNetwork": {
    "comments": [],
    "tags": {
      "label": {
        "value": "Social"
      },
      "itemName": {
        "value": "`name`"
      }
    },
    "name": "SocialNetwork",
    "type": "SocialNetwork",
    "literal": true,
    "content": [
      {
        "name": "name",
        "type": "string",
        "literal": true,
        "comments": [],
        "tags": {
          "label": {
            "value": "Name"
          }
        },
        "optional": false,
        "readonly": false
      },
      {
        "comments": [],
        "tags": {
          "label": {
            "value": "URL"
          }
        },
        "name": "url",
        "type": "URL",
        "optional": false,
        "readonly": false
      }
    ]
  },
  "StateOptions": {
    "comments": [],
    "tags": {},
    "name": "StateOptions",
    "type": "StateOptions",
    "varietyType": true,
    "content": [
      {
        "name": "StateOptions",
        "type": "'AL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'AK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'AZ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'AR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'CA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'CO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'CT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'DE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'FL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'GA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'HI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'ID'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'IL'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'IN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'IA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'KS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'KY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'LA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'ME'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'MD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'MA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'MI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'MN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'MS'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'MO'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'MT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'NE'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'NV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'NH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'NJ'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'NM'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'NY'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'NC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'ND'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'OH'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'OK'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'OR'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'PA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'RI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'SC'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'SD'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'TN'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'TX'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'UT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'VT'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'VA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'WA'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'WV'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'WI'",
        "literal": true,
        "comments": [],
        "tags": {}
      },
      {
        "name": "StateOptions",
        "type": "'WY'",
        "literal": true,
        "comments": [],
        "tags": {}
      }
    ]
  }
}